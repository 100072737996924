/* Privacy.css */

.documentionsubpage-subpage h1 {
  font-size: 30px;
  margin: 0px;
  margin-bottom: 10px;
}

.documentionsubpage-subpage h2 {
  margin: 0px;
}

.documentionsubpage-subpage .head-p {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  color: var(--secondary-color);
}

.documentionsubpage-subpage section {
  margin: 20px 0;
}

.documentionsubpage-subpage .h-content,
.documentionsubpage-subpage .h-content-nb {
  color: #333;
  line-height: 26px;
}

.documentionsubpage-subpage .h-content {
  padding: 20px 0;
  border-top: solid 1px #f2f2f2;
  border-top: solid 1px #f2f2f2;
  border-bottom: solid 1px #f2f2f2;
}