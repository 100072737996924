a {
  color: inherit
}

.index .section-page {
  width: 100%;
}

.index {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.index .navigation-bar-p {
  background-color: var(--primary-900);
  width: 100%;
  display: flex;
  justify-content: center;
}

.index .navigation-bar {
  align-items: center;
  align-self: stretch;
  background-color: var(--primary-900);
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  gap: 16px;
  max-width: 1468px;
}

.index .frame {
  height: 26.95px;
  position: relative;
  padding-left: 16px;
}

.index .navigation-menu {
  align-items: baseline;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 32px;
  margin-left: 16px;
  position: relative;
}

.index .products-wrapper {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--primary-500);
  height: 29px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.index .products {
  align-items: center;
  display: inline-flex;
  gap: 6px;
  position: relative;
}

.index .text-wrapper-2 {
  color: var(--neutral-100);
  font-weight: 600 !important;
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.index .text-wrapper-3 {
  color: var(--neutral-0);
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
  font-weight: 600 !important;
}

.index .div {
  color: var(--primary-500);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
  font-weight: 600 !important;
}

.index .footer {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
  padding: 60px 20%;
}

.index .frame-22 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.index .spendin {
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.index .div-6 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  position: relative;
}

.index .text-wrapper-21 {
  color: var(--secondary-700);
  font-weight: var(--material-theme-label-medium-font-weight);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.index .frame-23 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.index .text-wrapper-22 {
  color: var(--secondary-400);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.index .text-wrapper-23 {
  color: var(--secondary-400);
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.index .frame-24 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.index .divider {
  height: 1px;
  margin-top: -0.5px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.index .frame-25 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
}

.index .privacy-terms {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 16px;
  position: relative;
}

.index .text-wrapper-24 {
  color: var(--secondary-400);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.index .text-wrapper-25 {
  color: var(--secondary-400);
  font-family: var(--regular-type-18-font-family);
  font-size: var(--regular-type-18-font-size);
  font-style: var(--regular-type-18-font-style);
  font-weight: var(--regular-type-18-font-weight);
  letter-spacing: var(--regular-type-18-letter-spacing);
  line-height: var(--regular-type-18-line-height);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

@media (max-width: 900px) {
  .index .navigation-bar {
    flex-wrap: wrap;
    padding: 16px;
  }

  .index .navigation-menu {
    justify-content: flex-start;
    gap: 8px;
    justify-content: space-around;
  }

  .index .navigation-bar .button {
    display: none;
  }

  .index>footer,
  .index>.navigation-bar,
  .index>.section-page>div {
    padding: 24px 3% !important;
  }

  .index .frame-22 {
    width: 100%;
  }

  .index .unlock-actionable {
    font-size: var(--h1-mobile-font-size);
    font-family: var(--h1-mobile-font-family);
    font-style: var(--h1-mobile-font-style);
    font-weight: var(--h1-mobile-font-weight);
    letter-spacing: var(--h1-mobile-letter-spacing);
    line-height: var(--h1-mobile-line-height);
  }

  .index .div {
    font-family: var(--b2-mobile-font-family);
    font-size: var(--b2-mobile-font-size);
    font-style: var(--b2-mobile-font-style);
    font-weight: var(--b2-mobile-font-weight);
    letter-spacing: var(--b2-mobile-letter-spacing);
    line-height: var(--b2-mobile-line-height);
  }

  .index .text-wrapper-2 {
    font-family: var(--b2-mobile-font-family);
    font-size: var(--b2-mobile-font-size);
    font-style: var(--b2-mobile-font-style);
    font-weight: var(--b2-mobile-font-weight);
    letter-spacing: var(--b2-mobile-letter-spacing);
    line-height: var(--b2-mobile-line-height);
  }

  .index .text-wrapper-3 {
    font-family: var(--b2-mobile-font-family);
    font-size: var(--b2-mobile-font-size);
    font-style: var(--b2-mobile-font-style);
    font-weight: var(--b2-mobile-font-weight);
    letter-spacing: var(--b2-mobile-letter-spacing);
    line-height: var(--b2-mobile-line-height);
  }

  .index .your-one-stop {
    font-family: var(--b1-mobile-font-family);
    font-size: var(--b1-mobile-font-size);
    font-style: var(--b1-mobile-font-style);
    font-weight: var(--b1-mobile-font-weight);
    letter-spacing: var(--b1-mobile-letter-spacing);
    line-height: var(--b1-mobile-line-height);
  }

  .index .text-wrapper-4 {
    font-family: var(--h3-mobile-font-family);
    font-size: var(--h3-mobile-font-size);
    font-style: var(--h3-mobile-font-style);
    font-weight: var(--h3-mobile-font-weight);
    letter-spacing: var(--h3-mobile-letter-spacing);
    line-height: var(--h3-mobile-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .index .text-wrapper-5 {
    font-family: var(--h2-mobile-font-family);
    font-size: var(--h2-mobile-font-size);
    font-style: var(--h2-mobile-font-style);
    font-weight: var(--h2-mobile-font-weight);
    letter-spacing: var(--h2-mobile-letter-spacing);
    line-height: var(--h2-mobile-line-height);
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  .index .p {
    font-family: var(--b1-mobile-font-family);
    font-size: var(--b1-mobile-font-size);
    font-style: var(--b1-mobile-font-style);
    font-weight: var(--b1-mobile-font-weight);
    letter-spacing: var(--b1-mobile-letter-spacing);
    line-height: var(--b1-mobile-line-height);
    position: relative;
    text-align: center;
    width: 850px;
  }

  .index .text-wrapper-6 {
    font-family: var(--h3-mobile-font-family);
    font-size: var(--h3-mobile-font-size);
    font-style: var(--h3-mobile-font-style);
    font-weight: var(--h3-mobile-font-weight);
    letter-spacing: var(--h3-mobile-letter-spacing);
    line-height: var(--h3-mobile-line-height);
  }

  .index .text-wrapper-7 {
    font-family: var(--b2-mobile-font-family);
    font-size: var(--b2-mobile-font-size);
    font-style: var(--b2-mobile-font-style);
    font-weight: var(--b2-mobile-font-weight);
    letter-spacing: var(--b2-mobile-letter-spacing);
    line-height: var(--b2-mobile-line-height);
  }

  .index .text-wrapper-13 {
    font-family: var(--h2-mobile-font-family);
    font-size: var(--h2-mobile-font-size);
    font-style: var(--h2-mobile-font-style);
    font-weight: var(--h2-mobile-font-weight);
    letter-spacing: var(--h2-mobile-letter-spacing);
    line-height: var(--h2-mobile-line-height);
  }

  .index .text-wrapper-14 {
    font-family: var(--h3-mobile-font-family);
    font-size: var(--h3-mobile-font-size);
    font-style: var(--h3-mobile-font-style);
    font-weight: var(--h3-mobile-font-weight);
    letter-spacing: var(--h3-mobile-letter-spacing);
    line-height: var(--h3-mobile-line-height);
  }

  .index .text-wrapper-15 {
    font-family: var(--b1-mobile-font-family);
    font-size: var(--b1-mobile-font-size);
    font-style: var(--b1-mobile-font-style);
    font-weight: var(--b1-mobile-font-weight);
    letter-spacing: var(--b1-mobile-letter-spacing);
    line-height: var(--b1-mobile-line-height);
  }

  .index .text-wrapper-17 {
    font-family: var(--b1-mobile-font-family);
    font-size: var(--b1-mobile-font-size);
    font-style: var(--b1-mobile-font-style);
    font-weight: var(--b1-mobile-font-weight);
    letter-spacing: var(--b1-mobile-letter-spacing);
    line-height: var(--b1-mobile-line-height);
  }

  .index .text-wrapper-18 {
    font-family: var(--b2-mobile-font-family);
    font-size: var(--b2-mobile-font-size);
    font-style: var(--b2-mobile-font-style);
    font-weight: var(--b2-mobile-font-weight);
    letter-spacing: var(--b2-mobile-letter-spacing);
    line-height: var(--b2-mobile-line-height);
  }

  .index .text-wrapper-19 {
    font-family: var(--h3-mobile-font-family);
    font-size: var(--h3-mobile-font-size);
    font-style: var(--h3-mobile-font-style);
    font-weight: var(--h3-mobile-font-weight);
    letter-spacing: var(--h3-mobile-letter-spacing);
    line-height: var(--h3-mobile-line-height);
  }

  .index .text-wrapper-20 {
    font-family: var(--h2-mobile-font-family);
    font-size: var(--h2-mobile-font-size);
    font-style: var(--h2-mobile-font-style);
    font-weight: var(--h2-mobile-font-weight);
    letter-spacing: var(--h2-mobile-letter-spacing);
    line-height: var(--h2-mobile-line-height);
  }

  .index .text-wrapper-22 {
    font-family: var(--b2-mobile-font-family);
    font-size: var(--b2-mobile-font-size);
    font-style: var(--b2-mobile-font-style);
    font-weight: var(--b2-mobile-font-weight);
    letter-spacing: var(--b2-mobile-letter-spacing);
    line-height: var(--b2-mobile-line-height);
  }

  .index .text-wrapper-23 {
    font-family: var(--b2-mobile-font-family);
    font-size: var(--b2-mobile-font-size);
    font-style: var(--b2-mobile-font-style);
    font-weight: var(--b2-mobile-font-weight);
    letter-spacing: var(--b2-mobile-letter-spacing);
    line-height: var(--b2-mobile-line-height);
  }

  .index .text-wrapper-24 {
    font-family: var(--b2-mobile-font-family);
    font-size: var(--b2-mobile-font-size);
    font-style: var(--b2-mobile-font-style);
    font-weight: var(--b2-mobile-font-weight);
    letter-spacing: var(--b2-mobile-letter-spacing);
    line-height: var(--b2-mobile-line-height);
  }
}

@media (min-width: 901px) {
  .index .navigation-menu {
    justify-content: flex-end;
  }

  .index .navigation-bar {
    flex-wrap: no-wrap;
    padding: 20px 120px;
  }

  .index .frame-22 {
    width: 50%;
  }

  .index .unlock-actionable {
    font-size: var(--h1-desktop-font-size);
    font-family: var(--h1-desktop-font-family);
    font-style: var(--h1-desktop-font-style);
    font-weight: var(--h1-desktop-font-weight);
    letter-spacing: var(--h1-desktop-letter-spacing);
    line-height: var(--h1-desktop-line-height);
  }

  .index .div {
    font-family: var(--b3-desktop-font-family);
    font-size: var(--b3-desktop-font-size);
    font-style: var(--b3-desktop-font-style);
    font-weight: var(--b3-desktop-font-weight);
    letter-spacing: var(--b3-desktop-letter-spacing);
    line-height: var(--b3-desktop-line-height);
  }

  .index .text-wrapper-2 {
    font-family: var(--b3-desktop-font-family);
    font-size: var(--b3-desktop-font-size);
    font-style: var(--b3-desktop-font-style);
    font-weight: var(--b3-desktop-font-weight);
    letter-spacing: var(--b3-desktop-letter-spacing);
    line-height: var(--b3-desktop-line-height);
  }

  .index .text-wrapper-3 {
    font-family: var(--b3-desktop-font-family);
    font-size: var(--b3-desktop-font-size);
    font-style: var(--b3-desktop-font-style);
    font-weight: var(--b3-desktop-font-weight);
    letter-spacing: var(--b3-desktop-letter-spacing);
    line-height: var(--b3-desktop-line-height);
  }

  .index .your-one-stop {
    font-family: var(--b1-desktop-font-family);
    font-size: var(--b1-desktop-font-size);
    font-style: var(--b1-desktop-font-style);
    font-weight: var(--b1-desktop-font-weight);
    letter-spacing: var(--b1-desktop-letter-spacing);
    line-height: var(--b1-desktop-line-height);
  }

  .index .text-wrapper-4 {
    font-family: var(--h3-desktop-font-family);
    font-size: var(--h3-desktop-font-size);
    font-style: var(--h3-desktop-font-style);
    font-weight: var(--h3-desktop-font-weight);
    letter-spacing: var(--h3-desktop-letter-spacing);
    line-height: var(--h3-desktop-line-height);
  }

  .index .text-wrapper-5 {
    font-family: var(--h2-desktop-font-family);
    font-size: var(--h2-desktop-font-size);
    font-style: var(--h2-desktop-font-style);
    font-weight: var(--h2-desktop-font-weight);
    letter-spacing: var(--h2-desktop-letter-spacing);
    line-height: var(--h2-desktop-line-height);
  }

  .index .p {
    font-family: var(--b1-desktop-font-family);
    font-size: var(--b1-desktop-font-size);
    font-style: var(--b1-desktop-font-style);
    font-weight: var(--b1-desktop-font-weight);
    letter-spacing: var(--b1-desktop-letter-spacing);
    line-height: var(--b1-desktop-line-height);
  }

  .index .text-wrapper-6 {
    font-family: var(--h3-desktop-font-family);
    font-size: var(--h3-desktop-font-size);
    font-style: var(--h3-desktop-font-style);
    font-weight: var(--h3-desktop-font-weight);
    letter-spacing: var(--h3-desktop-letter-spacing);
    line-height: var(--h3-desktop-line-height);
  }

  .index .text-wrapper-7 {
    font-family: var(--b2-desktop-font-family);
    font-size: var(--b2-desktop-font-size);
    font-style: var(--b2-desktop-font-style);
    font-weight: var(--b2-desktop-font-weight);
    letter-spacing: var(--b2-desktop-letter-spacing);
    line-height: var(--b2-desktop-line-height);
  }

  .index .text-wrapper-13 {
    font-family: var(--h2-desktop-font-family);
    font-size: var(--h2-desktop-font-size);
    font-style: var(--h2-desktop-font-style);
    font-weight: var(--h2-desktop-font-weight);
    letter-spacing: var(--h2-desktop-letter-spacing);
    line-height: var(--h2-desktop-line-height);
  }

  .index .text-wrapper-14 {
    font-family: var(--h3-desktop-font-family);
    font-size: var(--h3-desktop-font-size);
    font-style: var(--h3-desktop-font-style);
    font-weight: var(--h3-desktop-font-weight);
    letter-spacing: var(--h3-desktop-letter-spacing);
    line-height: var(--h3-desktop-line-height);
  }

  .index .text-wrapper-15 {
    font-family: var(--b1-desktop-font-family);
    font-size: var(--b1-desktop-font-size);
    font-style: var(--b1-desktop-font-style);
    font-weight: var(--b1-desktop-font-weight);
    letter-spacing: var(--b1-desktop-letter-spacing);
    line-height: var(--b1-desktop-line-height);
  }

  .index .text-wrapper-17 {
    font-family: var(--b1-desktop-font-family);
    font-size: var(--b1-desktop-font-size);
    font-style: var(--b1-desktop-font-style);
    font-weight: var(--b1-desktop-font-weight);
    letter-spacing: var(--b1-desktop-letter-spacing);
    line-height: var(--b1-desktop-line-height);
  }

  .index .text-wrapper-18 {
    font-family: var(--b2-desktop-font-family);
    font-size: var(--b2-desktop-font-size);
    font-style: var(--b2-desktop-font-style);
    font-weight: var(--b2-desktop-font-weight);
    letter-spacing: var(--b2-desktop-letter-spacing);
    line-height: var(--b2-desktop-line-height);
  }

  .index .text-wrapper-19 {
    font-family: var(--h3-desktop-font-family);
    font-size: var(--h3-desktop-font-size);
    font-style: var(--h3-desktop-font-style);
    font-weight: var(--h3-desktop-font-weight);
    letter-spacing: var(--h3-desktop-letter-spacing);
    line-height: var(--h3-desktop-line-height);
  }

  .index .text-wrapper-20 {
    font-family: var(--h2-desktop-font-family);
    font-size: var(--h2-desktop-font-size);
    font-style: var(--h2-desktop-font-style);
    font-weight: var(--h2-desktop-font-weight);
    letter-spacing: var(--h2-desktop-letter-spacing);
    line-height: var(--h2-desktop-line-height);
  }

  .index .text-wrapper-22 {
    font-family: var(--b2-desktop-font-family);
    font-size: var(--b2-desktop-font-size);
    font-style: var(--b2-desktop-font-style);
    font-weight: var(--b2-desktop-font-weight);
    letter-spacing: var(--b2-desktop-letter-spacing);
    line-height: var(--b2-desktop-line-height);
  }

  .index .text-wrapper-23 {
    font-family: var(--b2-desktop-font-family);
    font-size: var(--b2-desktop-font-size);
    font-style: var(--b2-desktop-font-style);
    font-weight: var(--b2-desktop-font-weight);
    letter-spacing: var(--b2-desktop-letter-spacing);
    line-height: var(--b2-desktop-line-height);
  }

  .index .text-wrapper-24 {
    font-family: var(--b2-desktop-font-family);
    font-size: var(--b2-desktop-font-size);
    font-style: var(--b2-desktop-font-style);
    font-weight: var(--b2-desktop-font-weight);
    letter-spacing: var(--b2-desktop-letter-spacing);
    line-height: var(--b2-desktop-line-height);
  }
}


@media (max-width: 500px) {
  .index .p {
    width: 100%;
  }
}

@media (min-width: 501px) {
  .index .p {
    width: 100%;
  }
}