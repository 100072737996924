@media (max-width: 320px) {
  h2 {
    font-size: 24px;
  }
}

#root {}

/* Common values */
:root {
  --primary-color: #222;
  --typewriter-color: #222;
  --secondary-color: rgb(100, 116, 139);
  --nav-color: #111;
  --light-gray: #f5f5f5;
  --logo-color: #1d0627;
  --max-width: 1000px;
  --max-width-slim: 1000px;
  --content-padding: 10px 20px;
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: 'Inter var', sans-serif;
  }
}

body {
  font-weight: normal;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

a.underline-link {
  position: relative;
  text-decoration: none;
}

/* Underline animation */
a.underline-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  /* Adjust this value to control the distance of the underline from the text */
  width: 100%;
  height: 1px;
  /* Adjust this value to control the thickness of the underline */
  background-color: var(--nav-color);
  /* Adjust this value to set the color of the underline */
  transform: scaleX(0);
  /* Initial scale of the underline */
  transition: transform 0.3s ease-in-out;
  /* Adjust the transition properties as desired */
}

/* Hover effect */
a.underline-link:hover::after {
  transform: scaleX(1);
  /* Scale the underline to full width on hover */
}

.blur {
  filter: blur(5px);
  /* Adjust the blur intensity as needed */
  pointer-events: none;
  /* Prevent interaction with elements under the blurred layer */
}

* {
  color: #1d0627;
}

.double-gradient {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background: linear-gradient(rgb(230, 218, 244) 0%, rgba(251, 251, 251, 0.1) 100%);
}

/* .searchAnswer {
  display: inline-block;
  word-wrap: break-word;
  width: 100%;
  overflow: auto;
  overflow-wrap: break-word;
  white-space: break-spaces;
}

.searchAnswer * {
  padding: 0;
  margin: 0;
  margin-block: 0;
} */