.about-us-page {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;

  width: 100%;
  height: 100%;
}

.about-us-page .img {
  height: 26.95px;
  position: relative;
  width: 130.15px;
}

.about-us-page .div {
  color: var(--neutral-100);
  font-family: var(--b3-desktop-font-family);
  font-size: var(--b3-desktop-font-size);
  font-style: var(--b3-desktop-font-style);
  font-weight: var(--b3-desktop-font-weight);
  letter-spacing: var(--b3-desktop-letter-spacing);
  line-height: var(--b3-desktop-line-height);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.about-us-page .div-wrapper {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--primary-500);
  height: 29px;
  margin-bottom: -1px;
  margin-top: -1px;
  position: relative;
  width: 79px;
}

.about-us-page .text-wrapper-2 {
  -webkit-text-stroke: 1px #000000;
  color: var(--primary-500);
  font-family: var(--b3-desktop-font-family);
  font-size: var(--b3-desktop-font-size);
  font-style: var(--b3-desktop-font-style);
  font-weight: var(--b3-desktop-font-weight);
  left: -1px;
  letter-spacing: var(--b3-desktop-letter-spacing);
  line-height: var(--b3-desktop-line-height);
  position: absolute;
  text-align: justify;
  top: -2px;
  white-space: nowrap;
}

.about-us-page .button {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  justify-content: flex-end;
  position: relative;
}

.about-us-page .text-wrapper-3 {
  color: var(--neutral-0);
  font-family: var(--b3-desktop-font-family);
  font-size: var(--b3-desktop-font-size);
  font-style: var(--b3-desktop-font-style);
  font-weight: var(--b3-desktop-font-weight);
  letter-spacing: var(--b3-desktop-letter-spacing);
  line-height: var(--b3-desktop-line-height);
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.about-us-page .primary-button-instance {
  flex: 0 0 auto !important;
  left: unset !important;
  top: unset !important;
}

.about-us-page .frame-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--primary-900);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 120px;
  position: relative;
  width: 100%;
}

.about-us-page .tittle-descriptions {
  height: 56px;
  position: relative;
  width: 219px;
}

.about-us-page .tittle {
  height: 56px;
  position: relative;
  width: 221px;
}

.about-us-page .text-wrapper-4 {
  color: var(--neutral-0);
  font-family: var(--h2-desktop-font-family);
  font-size: var(--h2-desktop-font-size);
  font-style: var(--h2-desktop-font-style);
  font-weight: var(--h2-desktop-font-weight);
  left: 0;
  letter-spacing: var(--h2-desktop-letter-spacing);
  line-height: var(--h2-desktop-line-height);
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.about-us-page .frame-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: space-evenly;
  position: relative;
  flex-wrap: wrap;
}

.about-us-page .div-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 32px;
  position: relative;
  min-width: 300px;
  max-width: 500px;
}

.about-us-page .group {
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.about-us-page .frame-7 {
  align-items: center;
  align-self: stretch;
  justify-self: center;
  background-color: var(--neutral-0);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  padding: 120px;
  position: relative;
  width: 100%;
}

.about-us-page .frame-8 {
  align-items: center;
  align-self: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  max-width: 1126px;
}

.about-us-page .tittle-descriptions-2 {
  height: 56px;
  position: relative;
  width: 193px;
}

.about-us-page .text-wrapper-6 {
  color: var(--primary-500);
  font-family: var(--h2-desktop-font-family);
  font-size: var(--h2-desktop-font-size);
  font-style: var(--h2-desktop-font-style);
  font-weight: var(--h2-desktop-font-weight);
  left: 0;
  letter-spacing: var(--h2-desktop-letter-spacing);
  line-height: var(--h2-desktop-line-height);
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.about-us-page .p {
  align-self: stretch;
  color: var(--neutral-900);
  font-family: var(--b3-desktop-font-family);
  font-size: var(--b3-desktop-font-size);
  font-style: var(--b3-desktop-font-style);
  font-weight: var(--b3-desktop-font-weight);
  letter-spacing: var(--b3-desktop-letter-spacing);
  line-height: var(--b3-desktop-line-height);
  position: relative;
  width: auto !important;
}

.about-us-page .frame-9 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: center;
  position: relative;
  max-width: 1126px;
  justify-content: space-between;
}

.about-us-page .feature {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
  width: 50%;
}

.about-us-page .frame-10 {
  position: relative;
  width: 100%;
}

.about-us-page .group-10 {
  align-self: stretch;
  position: relative;
  width: 100%;
}

.about-us-page .frame-11 {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  padding: 0 16px;
}

.about-us-page .text-wrapper-7 {
  align-self: stretch;
  color: var(--secondary-900);
  font-family: var(--h3-desktop-font-family);
  font-size: var(--h3-desktop-font-size);
  font-style: var(--h3-desktop-font-style);
  font-weight: var(--h3-desktop-font-weight);
  letter-spacing: var(--h3-desktop-letter-spacing);
  line-height: var(--h3-desktop-line-height);
  margin-top: -1px;
  position: relative;
}

.about-us-page .text-wrapper-8 {
  align-self: stretch;
  color: var(--secondary-500);
  font-family: var(--b3-desktop-font-family);
  font-size: var(--b3-desktop-font-size);
  font-style: var(--b3-desktop-font-style);
  font-weight: var(--b3-desktop-font-weight);
  letter-spacing: var(--b3-desktop-letter-spacing);
  line-height: var(--b3-desktop-line-height);
  position: relative;
  text-align: justify;
}

.about-us-page .text-wrapper-9 {
  color: var(--secondary-900);
  font-family: var(--h3-desktop-font-family);
  font-size: var(--h3-desktop-font-size);
  font-style: var(--h3-desktop-font-style);
  font-weight: var(--h3-desktop-font-weight);
  letter-spacing: var(--h3-desktop-letter-spacing);
  line-height: var(--h3-desktop-line-height);
  margin-top: -1px;
  position: relative;
  white-space: wrap;
  width: fit-content;
}

.about-us-page .frame-12 {
  align-items: center;
  background-color: #121233;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  padding: 120px;
  position: relative;
  width: 100%;
}

.about-us-page .tittle-wrapper {
  height: 56px;
  position: relative;
  width: 298px;
}

.about-us-page .tittle-2 {
  height: 56px;
  position: relative;
  width: 300px;
}

.about-us-page .frame-13 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  flex-wrap: wrap;
  max-width: 1126px;
}

.about-us-page .frame-14 {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
}

.about-us-page .frame-15 {
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid;
  border-color: #5d59ff;
  border-radius: 14px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  justify-content: center;
  padding: 24px;
  position: relative;
}

.about-us-page .frame-16 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15px;
  position: relative;
  height: 68px;
}

.about-us-page .img-2 {
  height: 24px;
  position: relative;
  width: 24px;
}

.about-us-page .text-wrapper-10 {
  color: #ffffff;
  font-family: var(--h3-desktop-font-family);
  font-size: var(--h3-desktop-font-size);
  font-style: var(--h3-desktop-font-style);
  font-weight: var(--h3-desktop-font-weight);
  letter-spacing: var(--h3-desktop-letter-spacing);
  line-height: var(--h3-desktop-line-height);
  margin-top: -1px;
  position: relative;
  white-space: wrap;
  width: fit-content;
  top: -5px;
}

.about-us-page .text-wrapper-11 {
  align-self: stretch;
  color: #c6cada;
  flex: 1;
  font-family: var(--b3-desktop-font-family);
  font-size: var(--b3-desktop-font-size);
  font-style: var(--b3-desktop-font-style);
  font-weight: var(--b3-desktop-font-weight);
  letter-spacing: var(--b3-desktop-letter-spacing);
  line-height: var(--b3-desktop-line-height);
  position: relative;
  text-align: justify;
}

.about-us-page .frame-17 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #5d59ff;
  border-radius: 14px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  justify-content: center;
  padding: 24px;
  position: relative;
  width: 300px;
}

.about-us-page .frame-18 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #5d59ff;
  border-radius: 14px;
  display: flex;
  gap: 12px;
  height: 280px;
  justify-content: center;
  padding: 24px;
  position: relative;
  max-width: 400px;
  flex-wrap: wrap;
}

.about-us-page .footer {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 120px;
  padding: 120px;
  position: relative;
  width: 100%;
}

.about-us-page .frame-19 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 1200px;
}

.about-us-page .spendin {
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.about-us-page .div-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  position: relative;
}

.about-us-page .text-wrapper-12 {
  color: var(--secondary-500);
  font-family: var(--h3-desktop-font-family);
  font-size: var(--h3-desktop-font-size);
  font-style: var(--h3-desktop-font-style);
  font-weight: var(--h3-desktop-font-weight);
  letter-spacing: var(--h3-desktop-letter-spacing);
  line-height: var(--h3-desktop-line-height);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.about-us-page .frame-20 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.about-us-page .text-wrapper-13 {
  color: var(--secondary-400);
  font-family: var(--b3-desktop-font-family);
  font-size: var(--b3-desktop-font-size);
  font-style: var(--b3-desktop-font-style);
  font-weight: var(--b3-desktop-font-weight);
  letter-spacing: var(--b3-desktop-letter-spacing);
  line-height: var(--b3-desktop-line-height);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.about-us-page .text-wrapper-14 {
  color: var(--secondary-400);
  font-family: var(--b3-desktop-font-family);
  font-size: var(--b3-desktop-font-size);
  font-style: var(--b3-desktop-font-style);
  font-weight: var(--b3-desktop-font-weight);
  letter-spacing: var(--b3-desktop-letter-spacing);
  line-height: var(--b3-desktop-line-height);
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.about-us-page .frame-21 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.about-us-page .divider {
  height: 1px;
  margin-top: -0.5px;
  object-fit: cover;
  position: relative;
  width: 1200px;
}

.about-us-page .frame-22 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 91px;
  position: relative;
  width: 100%;
}

.about-us-page .text-wrapper-15 {
  color: var(--secondary-500);
  font-family: var(--b3-desktop-font-family);
  font-size: var(--b3-desktop-font-size);
  font-style: var(--b3-desktop-font-style);
  font-weight: var(--b3-desktop-font-weight);
  letter-spacing: var(--b3-desktop-letter-spacing);
  line-height: var(--b3-desktop-line-height);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.about-us-page .text-wrapper-16 {
  color: var(--secondary-500);
  font-family: var(--regular-type-18-font-family);
  font-size: var(--regular-type-18-font-size);
  font-style: var(--regular-type-18-font-style);
  font-weight: var(--regular-type-18-font-weight);
  letter-spacing: var(--regular-type-18-letter-spacing);
  line-height: var(--regular-type-18-line-height);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.about-us-page .at-kwazii-our {
  text-align: justify;
}

@media (max-width: 900px) {
  .about-us-page .at-kwazii-our {
    color: var(--neutral-0);
    font-family: var(--b1-mobile-font-family);
    font-size: var(--b1-mobile-font-size);
    font-style: var(--b1-mobile-font-style);
    font-weight: var(--b1-mobile-font-weight);
    letter-spacing: var(--b1-mobile-letter-spacing);
    line-height: var(--b1-mobile-line-height);
  }

  .home-page .funnel.group-2 .pipeline {
    width: 100%;
  }

  .about-us-page .text-wrapper-9 {
    max-width: 100%;
    white-space: pre-wrap;
  }

  .about-us-page .frame-9 {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (min-width: 901px) {
  .about-us-page .at-kwazii-our {
    color: var(--neutral-0);
    font-family: var(--b2-desktop-font-family);
    font-size: var(--b2-desktop-font-size);
    font-style: var(--b-desktop-font-style);
    font-weight: var(--b2-desktop-font-weight);
    letter-spacing: var(--b2-desktop-letter-spacing);
    line-height: var(--b2-desktop-line-height);
  }

  .home-page .funnel.group-2 .pipeline {
    height: 296.1px;
  }

  .about-us-page .text-wrapper-9 {
    max-width: fit-content;
  }

  .about-us-page .frame-9 {
    flex-wrap: nowrap;
  }
}