.primary-button {
  align-items: center;
  border-radius: 30px;
  display: inline-flex;
  gap: 10px;
  height: 52px;
  justify-content: center;
  left: 20px;
  padding: 13px 24px;
  position: relative;
}

.primary-button .text-wrapper {
  color: var(--neutral-0);
  font-family: var(--CTA-desktop-font-family);
  font-size: var(--CTA-desktop-font-size);
  font-style: var(--CTA-desktop-font-style);
  font-weight: var(--CTA-desktop-font-weight);
  letter-spacing: var(--CTA-desktop-letter-spacing);
  line-height: var(--CTA-desktop-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 150px;
}

.primary-button.enabled {
  background-color: var(--primary-500);
  top: 20px;
}

.primary-button.pressed {
  background-color: var(--primary-800);
  top: 236px;
}

.primary-button.disabled {
  background-color: var(--primary-300);
  top: 92px;
}

.primary-button.hover {
  background-color: var(--primary-600);
  top: 164px;
}