/* Common values */
:root {
    --docs-left-pane-head-color: rgb(15, 23, 42);
    --docs-left-pane-color: rgba(15, 23, 42, 0.8);
    --button-selected-color: rgb(241, 245, 249);
}

.documentation-page {
    display: flex;
    align-items: stretch;
    text-align: left;
    max-width: var(--max-width);
    margin-top: 0;
    padding-top: 0;
}

.left-pane {
    flex: 0 0 175px;
    border-right: solid 1px #f2f2f2;
    padding: 40px 10px;
    padding-left: 0;
}

.left-pane h2 {
    font-size: 16px;
    margin-bottom: 10px;
}

.left-pane ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.left-pane li {}

.content-pane {
    flex: 1;
    padding: 20px;
}

.left-pane li .head {
    font-weight: 500;
    font-size: 15px;
    color: var(--docs-left-pane-head-color);
    cursor: auto;
    padding: 6px;
    padding: 16px 0;
    display: block;
}

.left-pane li .head.first {
    padding-top: 0;
}

.left-pane li a {
    display: block;
    text-decoration: none;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 14px;
    color: var(--docs-left-pane-color);
    padding: 8px 0 8px 8px;
}

.left-pane a:hover {
    text-decoration: underline;
}

.left-pane li a.sub-li {
    padding-left: 15px;
}

.left-pane a.selected {
    background-color: var(--button-selected-color);
    border-radius: 5px;
}

.left-pane a.selected:hover {
    text-decoration: none;
}

.right-sidebar {
    flex: 0 0 150px;
    border-left: solid 1px #f2f2f2;
    padding: 20px;
    font-size: 11px;
    color: var(--secondary-color);
}

.right-sidebar h3 {
    margin-bottom: 10px;
}