/* TOS.css */

.content-tos {
  text-align: left;
  line-height: 22px;
  color: var(--primary-color);
  padding: 20px 120px;
}

.content-tos h1,
.content-tos h2 {
  font-weight: bold;
}