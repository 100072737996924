.home-page .button {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  justify-content: flex-end;
  position: relative;
  margin-left: 16px;
}

.home-page .primary-button-instance {
  flex: 0 0 auto !important;
  left: unset !important;
  top: unset !important;
}

.home-page .div-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--primary-900);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  padding: 120px;
  position: relative;
  width: 100%;
  top: -1px;
  background-image: url("https://assets-global.website-files.com/65030bfd09557ada51fe30e2/650425fd6cfd82111099c926_Blue%20radial.webp");
  background-position: 50%;
  background-repeat: no-repeat;
}

.home-page .how-it-works {
  background-position: 50%;
  background-position-y: 80%;
  background-size: 20%;
}

.home-page .hero-title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  justify-content: center;
  max-width: 1126px;
}

.home-page .div-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  max-width: 1126px;
}

.home-page .unlock-actionable {
  color: var(--neutral-100);
  margin-top: 16px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.home-page .your-one-stop {
  color: var(--secondary-300);
  position: relative;
  text-align: center;
  width: fit-content;
}

.home-page .design-component-instance-node {
  left: unset !important;
  top: unset !important;
}

.home-page .frame-2 {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  gap: 64px;
  overflow-x: scroll;
  position: relative;
  width: 100%;
}

.home-page .frame-wrapper {
  align-items: flex-start;
  display: flex;
  gap: 64px;
  height: 885px;
  position: relative;
  width: 100%;
}

.home-page .recording {
  height: 851.92px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.home-page .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.home-page .ellipse {
  background-color: var(--primary-500);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.home-page .ellipse-2 {
  background-color: var(--neutral-0);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.home-page .img {
  height: 851.23px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.home-page .recording-2 {
  height: 852.43px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.home-page .div-4 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  padding: 120px;
  position: relative;
  width: 100%;
}

.home-page .div-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.home-page .text-wrapper-4 {
  color: var(--primary-500);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-page .text-wrapper-5 {
  color: var(--secondary-900);
  position: relative;
  text-align: center;
  white-space: pre-wrap;
  overflow: hidden;
}

.home-page .p {
  color: var(--secondary-500);
  position: relative;
  text-align: center;
}

.home-page .features-frame {
  align-self: center;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  width: 100%;
  max-width: 1200px;
  justify-content: center;
}

.home-page .feature {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.home-page .frame-5 {
  position: relative;
  width: 100%;
}

.home-page .div-wrapper {
  height: 102px;
  position: relative;
}


.home-page .what-is-kwazii-how-wrapper {
  align-items: center;
  background-color: var(--primary-100);
  border-radius: 8px;
  box-shadow: 0px 0px 4px #00000040;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 16px;
  position: relative;
}

.home-page .what-is-kwazii-how {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 22.5px;
  margin-top: -1px;
  position: relative;
  width: 356px;
}

.home-page .frame-14 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  left: 16px;
  position: absolute;
  top: 299px;
}

.home-page .frame-15 {
  align-items: center;
  background-color: var(--neutral-100);
  border-radius: 8px;
  box-shadow: 0px 0px 4px #00000040;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 16px;
  position: relative;
}

.home-page .text-wrapper-11 {
  color: #000000;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 22.5px;
  margin-top: -1px;
  position: relative;
  width: 356px;
}

.home-page .frame-16 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.home-page .frame-17 {
  align-items: center;
  background-color: var(--primary-700);
  border-radius: 8px;
  box-shadow: 0px 0px 4px #00000040;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 16px;
  position: relative;
}

.home-page .text-wrapper-12 {
  color: var(--neutral-0);
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 22.5px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-page .tittle-descriptions {
  height: 104px;
  position: relative;
}

.home-page .tittle {
  height: 104px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page .text-wrapper-13 {
  color: var(--neutral-0);
  left: 0;
  text-align: center;
}

.home-page .text-wrapper-14 {
  color: var(--primary-500);
  padding: 5px 0;
}

.home-page .text-wrapper-15 {
  color: var(--secondary-300);
  position: relative;
  text-align: center;
}


.home-page .funnel.group-2 .pipeline {
  position: relative;
}

.home-page .testimonial-text {
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  position: relative;
  max-width: 1126px;
}

.home-page .we-decided-to-start {
  align-self: stretch;
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.36px;
  line-height: 27px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  max-width: 1024px;
  text-align: justify;
}

.home-page .span {
  color: #000000;
  font-style: italic;
}

.home-page .text-wrapper-16 {
  color: #5d59ff;
  font-style: italic;
}

.home-page .testimonial-giver {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 15px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home-page .ellipse-3 {
  height: 50px;
  object-fit: cover;
  position: relative;
  width: 50px;
}

.home-page .frame-18 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.home-page .text-wrapper-17 {
  color: var(--neutral-900);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-page .text-wrapper-18 {
  color: var(--neutral-600);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-page .closing-CTA-section {
  align-items: flex-start;
  justify-content: space-around;
  background-color: var(--primary-900);
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  gap: 32px;
  padding: 10px 120px;
  position: relative;
  width: 100%;
}

.home-page .frame-19 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}


.home-page .frame-20 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.home-page .tittle-wrapper {
  height: 36px;
  position: relative;
}

.home-page .tittle-2 {
  height: 36px;
  position: relative;
  width: 244px;
}

.home-page .text-wrapper-19 {
  color: var(--primary-500);
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.home-page .tittle-descriptions-2 {
  align-self: stretch;
  position: relative;
  width: 100%;
}

.home-page .tittle-3 {
  display: flex;
  gap: 24px;
  align-items: center;
}

.home-page .text-wrapper-20 {
  color: var(--primary-0);
}

@media (max-width: 500px) {
  .home-page .features-frame {
    flex-wrap: wrap;
  }

  .home-page .feature {
    max-width: calc(100%);
  }

  .home-page .div-2 {
    background-size: 0%;
  }

  .home-page .tittle-3 {
    flex-flow: column;
  }
}

@media (min-width: 501px) and (max-width: 900px) {
  .about-us-page .funnel.group-2 .pipeline {
    height: 200.1px;
  }

  .home-page .frame-19 {
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    gap: 0px;
  }

  .home-page .div-2 {
    background-size: 0%;
  }

  .home-page .feature {
    max-width: calc(33%);
  }

  home-page .features-frame {
    flex-wrap: wrap;
  }

  .home-page .closing-CTA-section {
    justify-content: space-between;
  }
}

@media (min-width: 901px) {
  .about-us-page .funnel.group-2 .pipeline {
    height: 296.1px;
  }

  .home-page .frame-19 {
    align-items: flex-start;
    justify-content: space-evenly;
    height: 249px;
  }

  .home-page .div-2 {}

  .home-page .feature {
    max-width: calc(33%);
  }
}